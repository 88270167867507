import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { Bind } from "../../decorators/bind.decorator";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-modal-footer")
@Component({
    selector: "s25-ng-modal-footer",
    template: `
        <div class="s25-modal--footer">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
            <div *ngIf="!content" class="buttons">
                <ng-container *ngIf="type === 'save'">
                    <s25-ng-loading-inline-static *ngIf="isLoading" [text]="''"></s25-ng-loading-inline-static>
                    <button class="aw-button aw-button--primary" (click)="onSave()">Save</button>
                    <button class="aw-button aw-button--outline" (click)="onCancel()">Cancel</button>
                </ng-container>
                <ng-container *ngIf="type === 'continue'">
                    <button class="aw-button aw-button--primary" (click)="onAffirm(true)">Continue</button>
                    <button class="aw-button aw-button--outline" (click)="onAffirm(false)">Cancel</button>
                </ng-container>
                <ng-container *ngIf="type === 'acknowledge'">
                    <button class="aw-button aw-button--primary" (click)="onAffirm(true)">Ok</button>
                </ng-container>
                <ng-container *ngIf="type === 'confirm'">
                    <button class="aw-button aw-button--primary" (click)="onAffirm(true)">Yes</button>
                    <button class="aw-button aw-button--outline" (click)="onAffirm(false)">No</button>
                </ng-container>
                <ng-container *ngIf="type === 'deny'">
                    <button class="aw-button aw-button--outline" (click)="onAffirm(true)">Yes</button>
                    <button class="aw-button aw-button--primary" (click)="onAffirm(false)">No</button>
                </ng-container>
                <ng-container *ngIf="type === 'delete'">
                    <button class="aw-button aw-button--danger--outline" (click)="onAffirm(true)">Delete</button>
                    <button class="aw-button aw-button--primary" (click)="onAffirm(false)">Cancel</button>
                </ng-container>
            </div>
        </div>
    `,
    styles: `
        .s25-modal--footer {
            padding: 1em;
            /*border-top: 1px solid;*/
        }

        .buttons {
            display: flex;
            gap: 0.5em;
            justify-content: end;
        }
    `,
})
export class S25ModalFooterComponent {
    @Input() type: "custom" | "save" | "acknowledge" | "confirm" | "deny" | "continue" | "delete" = "custom";
    @Input() content: TemplateRef<any>;
    @Input() isLoading = false;

    @Output() save = new EventEmitter<() => void>();
    @Output() cancel = new EventEmitter<void>();
    @Output() affirm = new EventEmitter<boolean>();
    @Output() isLoadingChange = new EventEmitter<boolean>();

    constructor(private changeDetector: ChangeDetectorRef) {}

    onSave() {
        this.isLoading = true;
        this.isLoadingChange.emit(this.isLoading);
        this.save.emit(this.stopLoading);
    }

    onCancel() {
        this.cancel.emit();
    }

    onAffirm(yes: boolean) {
        this.affirm.emit(yes);
    }

    @Bind
    stopLoading() {
        this.isLoading = false;
        this.isLoadingChange.emit(this.isLoading);
        this.changeDetector.detectChanges();
    }
}
