import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { UserprefService } from "../../services/userpref.service";
import { S25VirtualGridAbstractItem } from "../s25-virtual-grid/s25.virtual.grid.abstract.item";
import { MPG } from "./s25.meeting.pattern.grid.component";
import { PopoverComponent } from "../s25-popover/popover.component";
import { StateService } from "../../services/state.service";

@Component({
    selector: "s25-ng-meeting-pattern-grid-event",
    template: `
        <div *ngIf="item.data.isFrame" class="frame"></div>
        <div
            *ngIf="!item.data.isFrame"
            class="item"
            [class.unDraggable]="!item.draggable || $any(item)._gridData.truncated"
            [class.hasActiveUser]="activeUser"
            [class.broughtToFront]="item.data.broughtToFront"
            [class.sortedBy]="sortedBy"
            [title]="item.data.name"
        >
            <s25-ng-time-bubble
                *ngIf="showBubbles"
                [position]="'up'"
                [hours]="item.data.startHour"
                [is24Hours]="is24Hours"
            ></s25-ng-time-bubble>
            <div class="item--inner">
                <div class="itemText">{{ item.data.name }} ({{ item.data.headCount }})</div>
                <s25-popover
                    #popover
                    [openTrigger]="'click'"
                    [modelBean]="{ popoverTemplate: itemPopoverTemplate }"
                    (mousedown)="$event.stopPropagation()"
                    [tabIndex]="0"
                    (keydown.enter)="forceOpen($event, popover)"
                    (keydown.space)="forceOpen($event, popover)"
                    (shown)="onPopoverOpen($event)"
                    (hidden)="onPopoverClose()"
                    [attr.aria-label]="'Info'"
                    [placement]="'bottom bottom-start bottom-end right right-top right-bottom top top-start top-end'"
                >
                    <s25-ng-icon class="itemCog" [type]="'cog'" [size]="'1.25em'"></s25-ng-icon>
                </s25-popover>
                <s25-ng-bubble *ngIf="activeUser" [position]="'down'" class="activeUser">
                    {{ activeUser }}
                </s25-ng-bubble>
            </div>
            <s25-ng-time-bubble
                *ngIf="showBubbles"
                [position]="'down'"
                [hours]="item.data.endHour"
                [is24Hours]="is24Hours"
            ></s25-ng-time-bubble>
        </div>

        <ng-template #itemPopoverTemplate>
            <div class="qtip-content mpgEventPopover" [tabIndex]="0">
                <div class="eventBox">
                    <div class="boxHeader">
                        <a class="boxHeaderName" (click)="goToItem()">{{ item.data.name }}</a>
                    </div>

                    <div *ngIf="true || item.data.roomId === -1" class="relevanceSort">
                        <button class="aw-button aw-button--primary" (click)="onRelevanceSort()">
                            Sort Locations By Relevance
                        </button>
                    </div>

                    <div class="boxBody">
                        <div *ngIf="item.data.organization" class="flexColumn">
                            <span class="c-dataLabel">Organization:</span>
                            <span>{{ item.data.organization.name }}</span>
                        </div>

                        <div *ngIf="item.data.organization?.partitions?.length" class="flexColumn">
                            <span class="c-dataLabel">Partition Preferences:</span>
                            <ul>
                                <li *ngFor="let pref of item.data.organization.partitions">
                                    <strong>Priority: {{ pref.priority }}</strong>
                                    <ul>
                                        <li *ngFor="let partition of pref.list">
                                            {{ partition.name }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="item.data.features?.length" class="flexColumn">
                            <span class="c-dataLabel">Features:</span>
                            <ul>
                                <li *ngFor="let feature of item.data.features">{{ feature.name }}</li>
                            </ul>
                        </div>

                        <div class="flexColumn">
                            <span class="c-dataLabel">Enrollment:</span>
                            <span>{{ item.data.headCount }}</span>
                        </div>

                        <div *ngIf="item.data.canHaveSOC" class="flexColumn">
                            <span class="c-dataLabel">StandOut Classroom:</span>
                            <span>TODO</span>
                            <!--<s25-toggle-button [(modelValue)]=""></s25-toggle-button>-->
                        </div>

                        <div *ngIf="item.data.frame?.frame?.size" class="flexColumn">
                            <span class="c-dataLabel">Overlapping Items:</span>
                            <ul class="overlappingItems">
                                <li *ngFor="let frameItem of item.data.frame?.frame">
                                    <button
                                        class="bringToFront ngAnchor"
                                        title="Bring To Front"
                                        (click)="bringToFront(frameItem)"
                                    >
                                        {{ frameItem.data.name }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    styles: `
        :host {
            /* The variables used here can be set outside of the component for customization */
            --item-color: var(--grid-item-color, black);
            --item-background: var(--grid-item-background, #cff3d2);
            --item-undraggable-color: var(--grid-item-undraggable-color, white);
            --item-undraggable-background: var(--grid-item-undraggable-background, #616161);
            --item-border: var(--grid-item-border, 1px solid #333333);
        }

        ::ng-deep .nm-party--on s25-ng-meeting-pattern-grid-event {
            /* The variables used here can be set outside of the component for customization */
            --item-color: var(--grid-item-color, black);
            --item-background: var(--grid-item-background, #cff3d2);
            --item-undraggable-color: var(--grid-item-undraggable-color, white);
            --item-undraggable-background: var(--grid-item-undraggable-background, #2e2e2e);
            --item-border: var(--grid-item-border, 1px solid #000000);
        }

        .item {
            --item-height: calc(100% - 3 * var(--border-width));

            background: var(--item-background);
            color: var(--item-color) !important;
            height: var(--item-height);
            border: var(--item-border);
            font-size: var(--font-size-item);
            display: flex;
            translate: 0 1px;
            position: relative;
        }

        .item--inner {
            padding: 2px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-self: center;
            align-items: center;
        }

        .item.unDraggable {
            background: var(--item-undraggable-background);
            color: var(--item-undraggable-color) !important;
        }

        .item.hasActiveUser {
            box-shadow: 0px 0px 5px 1px #cff3d2;
        }

        .itemText {
            width: calc(100% - 1.5em);
            overflow: hidden;
            text-overflow: ellipsis;
            height: var(--item-height);
        }

        s25-popover {
            align-self: end;
            cursor: pointer;
            overflow: hidden;
        }

        .flexColumn {
            display: flex;
            flex-direction: column;
        }

        .qtip-content {
            max-height: 50vh;
            overflow-y: scroll;
        }

        ::ng-deep s25-ng-meeting-pattern-grid-event s25-ng-icon .c-svgIcon {
            vertical-align: text-top;
        }

        .relevanceSort {
            text-align: center;
            padding: 1em 0 !important;
        }

        .activeUser {
            position: absolute;
            bottom: 0;
            left: 50%;

            --bubble-background-color: #cff3d2;
        }

        .frame {
            border: 2px dashed #03f;
            height: 100%;
            pointer-events: none;
            z-index: 10; /* Has to be in front of items */
            position: relative;
        }

        .sortedBy {
            border: 2px dashed red;
        }

        .bringToFront {
            border: none;
            background: transparent;
            cursor: pointer;
        }

        .broughtToFront {
            z-index: 1;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25MeetingPatternGridEventComponent
    extends S25VirtualGridAbstractItem<MPG.ItemData>
    implements OnInit, OnChanges
{
    @Input() sortedBy: boolean; // Is the item the subject of "sort by relevance"
    @Output() sortByRelevance = new EventEmitter<MPG.Item>();

    is24Hours: boolean;
    showBubbles: boolean;
    activeUser: string;
    returnFocusTo: HTMLElement;

    constructor(private changeDetector: ChangeDetectorRef) {
        super();
    }

    async ngOnInit() {
        this.is24Hours = await UserprefService.getIs24HourTime();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dragging) this.onDraggingChange();
        if (changes.moving) this.onMovingChange();
    }

    onDraggingChange() {
        if (this.dragging) {
            this.showBubbles = this.dragging?.item === this.item || this.dragging.item.linkedItems.has(this.item.id);
        } else {
            this.showBubbles = false;
        }
    }

    onRelevanceSort() {
        this.sortByRelevance.emit(this.item);
    }

    onMovingChange() {
        // If moving and active user, show active user
        if (this.moving && this.item.data.activeUser) this.activeUser = this.item.data.activeUser;
        // If no longer moving, hide active user
        else if (!this.moving) this.activeUser = null;
    }

    bringToFront(item: MPG.Item) {
        for (let frameItem of item.data.frame.frame) frameItem.data.broughtToFront = false;
        item.data.broughtToFront = true;
        this.changeDetector.detectChanges();
    }

    forceOpen(event: Event, popover: PopoverComponent) {
        event.preventDefault(); // Prevent spacebar from scrolling page
        popover.forceOpen();
    }

    onPopoverOpen(popoverModel: PopoverComponent["modelBean"]) {
        this.returnFocusTo = document.activeElement as HTMLElement;
        setTimeout(() => {
            popoverModel.popoverElement.querySelector(".mpgEventPopover").focus();
        }, 0);
    }

    onPopoverClose() {
        // Restore focus
        this.returnFocusTo.focus();
        this.returnFocusTo = null;
    }

    goToItem() {
        StateService.gotoItem({ itemTypeId: 1, itemId: this.item.data.event.itemId, forceView: "details" }, true);
    }
}
